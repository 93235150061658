:root {
    --header-height: 65px;
}

/* header start */

.subMenuDiv {
    transition: all .32s;
}

/* header end */

/* SelectedWork start */

.selectedWorkSwiper {
    overflow: visible !important;
}

.selectedWorkSwiperImage {
    /* height: 20%; */
    box-sizing: border-box;
}

.selectedWorkSwiperImage .swiper-slide {
    width: 25%;
    height: 400px;
}

.selectedWorkSwiperImage .swiper-slide-thumb-active {
    opacity: 1;
}

.selectedWorkSwiperImage .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* SelectedWork end */

/* ClientReviews start */

.client-image .logo {
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .19);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .19);
    background-size: 62%;
}

.OurWork .swiper-wrapper {
    align-items: center;
}

.reviewSwiper .swiper-button-prev,
.reviewSwiper .swiper-button-next,
.OurWork .swiper-button-prev,
.OurWork .swiper-button-next {
    position: relative !important;
    top: auto !important;
    color: white !important;
}

.reviewSwiper .swiper-button-prev:after,
.reviewSwiper .swiper-button-next:after,
.OurWork .swiper-button-prev:after,
.OurWork .swiper-button-next:after {
    font-size: 15px !important;
}

.reviewSwiper .swiper-button-prev,
.OurWork .swiper-button-prev {
    left: 0px !important;
}

.reviewSwiper .swiper-button-next,
.OurWork .swiper-button-next {
    right: 0px !important;
}

/* ClientReviews end */

/* ContactDrawer start */

.ContactDrawer .drawerAnimation {
    transition: all .4s;
}

.ContactDrawer .react-tel-input .flag-dropdown,
.ContactDrawer .react-tel-input .selected-flag,
.ContactDrawer .react-tel-input .selected-flag:hover,
.ContactDrawer .react-tel-input .selected-flag:focus {
    background-color: transparent !important;
    border: none;
}

.ContactDrawer .react-tel-input .country-list .country.highlight,
.ContactDrawer .react-tel-input .country-list .country:hover {
    background-color: #252f43 !important;
}

.react-tel-input .country-list .search {
    background-color: #111827 !important;
    padding: 10px !important;
}

/* ContactDrawer end */

/* reCaptcha start */

.grecaptcha-badge {
    z-index: 1000;
    box-shadow: none !important;
}

/* reCaptcha end */

/* SelectedWork page start */

a.projectDetails div {
    background-position: 50% top;
    /* background-repeat: no-repeat; */
}

/* SelectedWork page end */

/* ServiceDetails start */

.ServiceDetails .swiper-pagination-bullet,
.OurWork .swiper-pagination-bullet {
    background: #cbd5e1 !important;
}

.ServiceDetails .swiper-pagination-bullet-active,
.OurWork .swiper-pagination-bullet-active {
    background: #6bf3ca87 !important;
}

/* ServiceDetails end */

/* Custom Background start */

.bgPatternDiv {
    left: calc(52.96944% - 1600px / 2);
    position: absolute;
    width: 1600px;
}

.bgPatternDiv img {
    flex: none;
    height: 1100px;
    overflow: hidden;
    width: 100%;
}

/* .bgPatternDiv {
    left: calc(49.96943952046964% - 1091px / 2);
    position: absolute;
    width: 1091px;
}

.bgPatternDiv img {
    flex: none;
    height: 818px;
    overflow: hidden;
    width: 100%;
} */

.borderAnimation {
    animation: spin 7s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Custom Background end */